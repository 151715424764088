import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from '../../Layout';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import PaginatedTable from '../../Material/PaginatedTableMedicineDelivery';
import {filter, includes, cloneDeep} from 'lodash';
import OrderService from '../../../ApiClients/OrderService';
import { SelectorField, CheckBoxField, TextFieldInput } from '../../FormsWidgets';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IOrder, IBuilding, IBatch, IIOrder } from '../../../Library/types';
import LoaderBeauty from '../../Material/activityProgress';
import  Checkbox  from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '../../Material/MultiSelect';
import Loader from '../../Material/Loader';
import { ValidateText } from '../../../Library/vc_general';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY:'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
    width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        overflowX: 'auto',
        borderWidth: 2, 
        borderColor: '#ff972b',
        borderStyle: 'solid',
    },
    table:{
        //tableLayout:'fixed'
        width: '100%',
    },
    row:{
        //wordWrap:'break-word'
        width:'100%',
        //backgroundColor:'#ddd'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth:'200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const fields_line = [
    [
        {
            id: 'article',
            name: 'Artículo',
            required: true,
            style:3,
            typeField:1,
            helper:'',
            maxText:100,
            multiple: false
        },
        // {
        //     id: 'animals',
        //     name: 'Animales',
        //     required: true,
        //     style:3,
        //     helper:'',
        //     type:'number',
        //     maxText:100,
        //     typeField:2,
        //     multiline:false
        // },
        // {
        //     id: 'weight',
        //     name: 'Peso',
        //     required: true,
        //     type:'number',
        //     style:3,
        //     helper:'',
        //     maxText:100,
        //     typeField:2,
        //     multiline:false
        // },
        {
            id: 'qty',
            name: 'Unidades',
            required: true,
            style:3,
            type:'number',
            inputType:'int',
            helper:'',
            maxText:100,
            typeField:2,
            multiline:false
        }
    ],    
];

interface IFormListadoItemProps {
    classes: any;
    options:any;
    handleCreateItem:Function;
    handleCloseItemForm: Function;
    loading:boolean;
    item?:any;
}

interface IFormListadoItemState {
    itemListado:any;
    warningOpen:boolean;
    warningTitle:string;
}


class MedicineFormAddItem extends Component<IFormListadoItemProps, IFormListadoItemState> {
    constructor(props: IFormListadoItemProps) {
        super(props);
        this.state = {
            itemListado: (props.item || {}),
            warningOpen:false,
            warningTitle:"Reunión CAE"
        };
    }

    handleChangeUser=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);   
        newItemListado[id] = event.target.value;
        this.setState({ itemListado: newItemListado });
    }

    handleChangeBatchLine=(id:any, event:any, index:number)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);   
        newItemListado.batch[index][id] = event.target.value;
        this.setState({ itemListado: newItemListado });
    }


    shouldDisableToday = (date:any) => {
        return false;
    }

    handleChangeCheckbox= (id:string, event:any) =>{        
        let newItemListado = Object.assign({}, this.state.itemListado);       
        newItemListado[id] = event.target.checked;

        if(newItemListado[id])
            newItemListado["prioridad"] = 20;
        this.setState({ itemListado: newItemListado });        
    }

    handleChangeDate=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event;
        this.setState({ itemListado: newItemListado });
    }

    handleRenderFields = () => {
        const {itemListado} = this.state;
        const {options} = this.props;

        let childs = [];

        let fieldsTMP = fields_line.map((n0:any, index:number)=>{                        
            let fieldsN0 = n0.map((n:any, index01:number)=>{
                const verified_field:any = this.VerifyField(n, itemListado);

                switch(n.typeField){
                    case 1:
                        return(
                            <div style={{ backgroundColor:'#fff', borderRadius:11, width:'100%' }}>
                                <List 
                                    id={n.id} 
                                    data={itemListado[n.id]} 
                                    multiple={false} 
                                    name={n.name} 
                                    helper={verified_field[1]}
                                    noError={verified_field[0]}
                                    placeholder={n.name} 
                                    options={options[n.id]?options[n.id]:[]}  //?filter(options[n.id], function(o){return o.activated}):[]
                                    handleChangeSelect={(e:any, list:any)=>this.handleListChange(n.id,e,list)}
                                />
                            </div>
                        );
                    case 2:
                        return(
                            <TextFieldInput
                                key={`${index01}_${n.id}`}
                                name={n.name}
                                id={n.id}
                                fieldsData={itemListado}
                                handleFieldChange={(id:string, evt:any)=>this.handleChangeUser(id, evt)}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                required={n.required}
                                style={n.style}
                                helper={verified_field[1]}
                                maxText={n.maxText}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                                noError={verified_field[0]}
                                multiline={n.multiline}
                            />
                        );         
                    case 4:
                        return(
                            <div style={{ backgroundColor:'#fff', borderRadius:11, width:'100%' }}>
                                <List 
                                    id={n.id} 
                                    helper={verified_field[1]}
                                    noError={verified_field[0]}
                                    data={itemListado[n.id]} 
                                    multiple={true} 
                                    name={n.name} 
                                    placeholder={n.name} 
                                    options={itemListado.article?itemListado.article.batch:[]}
                                    handleChangeSelect={(e:any, list:any)=>this.handleListChange(n.id,e,list)}
                                />
                            </div>
                        );
                    default:
                        return(null);
                }
            })
            return (
                <form key={index} noValidate autoComplete="off">
                    {fieldsN0}
                </form>
            )
        });        

        
       return (
            fieldsTMP
       );
    }

    VerifyField = (field:any, data:any) => {
        // let answer = null;

        // if(header)
        //     answer = c_report.journalTable[0][question.id];
        // else
        //     answer = c_report.journalTable[0].farmJournalLines[building+1][origin][question.id];
        // // if(!question.required)
        // //     return [true, ""];

        if(field.required && (data[field.id] === null || data[field.id] === undefined || data[field.id].length == 0))
            return [false, "Debe rellenar este campo"];

        if(field.required || (!field.required && data[field.id] !== null || data[field.id] !== undefined && data[field.id].length > 0))
            switch(field.inputType) {
                case "int":
                    if(!ValidateText(field.inputType, data[field.id], true)[0])
                        return [false, "El campo debe ser un entero"];
                    break;
                case "float":
                    if(!ValidateText(field.inputType, data[field.id], true)[0])
                        return [false, "El campo debe ser un entero o decimal"];
                    break;
            }

        return [true, ""]
    }

    verifyForm=(itemListado:any)=>{
        let ret = true;

        fields_line.map((n0:any, index:number)=>{                        
            n0.map((n:any, index:number)=>{

                const verified_field:any = this.VerifyField(n, itemListado);
                if(!verified_field[0])
                    ret = false;
            })
        });   
        
        return ret;
    }

    handleResetItemForm = () => {
        this.setState({itemListado:undefined});
    }

    handleCloseWarningMessage=()=>{
        this.setState({warningOpen:false});
    }

    handleListChange=(id:any, e:any, list:any)=> {
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = list;

        if(id == "article") {
            newItemListado.batch = undefined;
            // if(list == null) {
            //     newItemListado.batch = undefined;
            //     list = []
            // }
            // this.setState((prevstate:IIMedicineDeliveriesState)=>({
            //     itemListado: newItemListado,
            //     options:{
            //         ...prevstate.options,
            //         batch:(list.batch || [])
            //     }
            // }));
        }
        this.setState({ itemListado: newItemListado });
    }

    handleChangeCheckBox=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.itemListado);
        newItemListado[id] = event.target.checked;
        this.setState({ itemListado: newItemListado });
    } 

    render() {
        const {itemListado} = this.state;
        const {options} = this.props;

        const valid_form = this.verifyForm(itemListado);

        return (
            <div>
            <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data'>
                <DialogTitle id="form-dialog-title">{'Añadir item'}</DialogTitle>
                <DialogContent>
                    {this.handleRenderFields()}
                </DialogContent>
                <DialogActions>
                    {!this.props.loading?
                        <div style={{ display:'flex', gap:25 }}>
                            <Button onClick={()=>this.props.handleCloseItemForm()} className='cancel-dialog'>
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!valid_form} onClick={()=>this.props.handleCreateItem(this.state.itemListado)} className={`confirm-dialog ${(!valid_form?'confirm-dialog-disable':'')}`}>
                                {'Guardar'}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.warningOpen} onClose={this.handleCloseWarningMessage} className='dialog-data dialog-warning' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {this.state.warningTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¡Atención! Será obligatorio añadir en el apartado comentarios el nombre de la subcontrata.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>                
                    <Button onClick={this.handleCloseWarningMessage} autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }
}
export default withStyles(styles)(MedicineFormAddItem);