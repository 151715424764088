import { IFarm, IReport } from '../Library/types';
import  {farms}  from '../ApiClients/DummyData'

let listFarms:any = undefined;

let listFarmDetail:Array<IFarm> = [];

let listProducts:any=undefined;

let listPedidos:any=undefined;

let listPedidosRelated:any=undefined;

let listAvailableReports:Array<IReport> = [];

let listWarehouses:any = undefined;

class appData {

    static getListFarms(){
        return listFarms;
    }

    static setListFarms(newListFarms:any){
        listFarms=newListFarms;
    }

    static getListAvailableReports(){
        return listAvailableReports;
    }

    static setListAvailableReports(reports:Array<IReport>){
        listAvailableReports = reports;
    }

    static getListFarmDetail(){
        return listFarmDetail;
    }

    static setListFarmDetail(farm:IFarm){
        listFarmDetail.push(farm);
    }

    static getListProducts(){
        return listProducts;
    }

    static setListProducts(newListProducts:any){
        listProducts=newListProducts;
    }

    static getListPedidos(){
        return listPedidos;
    }

    static setListPedidos(newListPedidos:any){
        listPedidos=newListPedidos;
    }
    
    static getListPedidosRelated(){
        return listPedidosRelated;
    }

    static setListPedidosRelated(newListPedidosRelated:any){
        listPedidosRelated=newListPedidosRelated;
    }

    static getListWarehouses(){
        return listWarehouses;
    }

    static setListWarehouses(newList:any){
        listWarehouses=newList;
    }
}
export default appData;