import React from 'react';
import { IFarm, IWarehouse } from '../Library/types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
import farmIcon from '../Assets/farm.png';
import ReportIcon from '@material-ui/icons/NoteAddOutlined';
import ChangeIcon from '@mui/icons-material/ChangeCircle';
import InventoryIcon from '@mui/icons-material/BookmarkAdded';

import sectorAviIcon from '../Assets/list-icon-avi.svg'
import sectorVacIcon from '../Assets/list-icon-vac.svg'
import sectorCerIcon from '../Assets/list-icon-cer.svg'

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
        width:40,
        height:40
    },
    root: {
        borderRadius: 0,
    },
    item: {
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 10px 5px rgba(0,0,0,0.31)',
        cursor: 'pointer',        
        position: 'relative',        
        backgroundColor:'#fff',
        paddingBottom: '50px',
        margin: '5px 10px',
        borderRadius: '15px',

    },
    icon: {
        // paddingTop: '3px',
        // paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },
    bottomActionsContainer: {
        position: 'absolute',
        top: 65,
        left: 15,
        right: 0,
        height: '30px',
        borderTop: '1px solid rgba(0,0,0,0.31)',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        padding: '5px 0px',
        color: '#284770',
    },
    bottomRightActionsContainer: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        bottom: '0px',
        zIndex: 1,
        fontSize:'12px'
    },
    promotion: {
        //backgroundColor: '#f00',
        display: 'inline-block',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    separator: {
        display: 'inline-block',
        borderRight: '1px solid rgba(0,0,0,0.31)',
        verticalAlign: 'middle',
        height: '100%',
        marginLeft: '15px'
    },
    textField: {
        marginLeft: '10px',
        marginRight: '10px',
        width: 60,
    },
    mainHeader: {
        whiteSpace:'nowrap',
        overflowX:'auto'
    }
}));

const FarmListItem = (props: ListChildComponentProps) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let item: IWarehouse = data[index];

    

    return (        
        <div style={style} key={index} >
            <ListItem classes={{ root: classes.item }}>
                <ListItemIcon>              
                    {(() => {
                        switch (item.sector) {
                        case 1:
                            return <Avatar alt="Farm Icon" src={sectorAviIcon} classes={{
                                root: classes.root,
                                img: classes.img
                            }} />
                        case 2:
                            return <Avatar alt="Farm Icon" src={sectorCerIcon} classes={{
                                root: classes.root,
                                img: classes.img
                            }} />
                        case 3:
                            return <Avatar alt="Farm Icon" src={sectorVacIcon} classes={{
                                root: classes.root,
                                img: classes.img
                            }} />                        
                        default:
                            return null
                        }
                    })()}                          
                </ListItemIcon>
                <ListItemText 
                    primary={`${item.business} - ${item.description}`} 
                    secondary={`${item.id} - ${item.type}`} 
                    className={classes.mainHeader} 
                />
                <div className={classes.bottomActionsContainer}>
                    {/*`Ocupación: ${item.ocupacion} animales`*/}
                    <div  className={classes.bottomRightActionsContainer}>
                        <div className={classes.separator} />
                        <IconButton onClick={item.onMedicineAskClick} className={classes.icon}>
                            <ReportIcon/>
                        </IconButton>
                    </div>
                    <div  className={classes.bottomRightActionsContainer} style={{ right:45 }}>
                        <div className={classes.separator} />
                        <IconButton onClick={item.onChangeBetweenWarehouseClick} className={classes.icon}>
                            <ChangeIcon/>
                        </IconButton>
                    </div>
                    <div  className={classes.bottomRightActionsContainer} style={{ right:90 }}>
                        <div className={classes.separator} />
                        <IconButton onClick={item.onMedicineInventory} className={classes.icon}>
                            <InventoryIcon/>
                        </IconButton>
                    </div>
                </div>
            </ListItem>
        </div>
    );
}

FarmListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default FarmListItem;