export const farms = [
    {
      "dataareaid": "ac",
      "sector":1,
      "id": "31002",
      "name": "FERNANDEZ HERRERO FCO JAVIER",
      "POBLACION": "SAMBOAL",
      "EMAIL": "sprunera@vallcompanys.es",
      "LOTE": "054",
      "tipoGranja": 2,
      "rol": 10,
      "allowMedicado": 1,
      "procedencias": {
        "loteActual":
          {
            "altas":0,
            "bajas":0,
            "informacion":[
              {
                "nombre":"Otros",
                "codMadres":"",
                "codDestete":"",
                "altas":0,
                "bajas":0
              }
            ]
          }   
        ,
        "loteAnterior":
          {
            "altas":100,
            "bajas":78,
            "informacion":[
              {
                "nombre":"Otros",
                "codMadres":"",
                "codDestete":"",
                "altas":0,
                "bajas":0
              },
              {
                "nombre":"AGROPECUARIA DE CALERUEGA SL",
                "codMadres":"ES090640000004",
                "codDestete":"",
                "altas":100,
                "bajas":78
              },
            ]
          }
        
      },
      "pienso":{
        "loteActual":{
          "altas":0,
          "bajas":30000,
          "informacion":
          [
            {
              "tipo":"null",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-31",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-21",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
          ]
        },
        "loteAnterior":{
          "altas":0,
          "bajas":242626,
          "informacion":[
            {
              "tipo":"CL-02",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"null",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-31",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-21",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CL-04",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
          ]
        }
      },
      "lotes":[
        {
          "lote":"036",
          "IT":2.274,
          "BAJ":5.19,
          "PRO":"INT",
          "PRO2":1.59,
        },
        {
          "lote":"037",
          "IT":2.532,
          "BAJ":0.09,
          "PRO":"CAP",
          "PRO2":1.84,
        },
        {
          "lote":"038",
          "IT":2.158,
          "BAJ":2,
          "PRO":"INT",
          "PRO2":0.15,
        },
        {
          "lote":"039",
          "IT":2.183,
          "BAJ":3.4,
          "PRO":"INT",
          "PRO2":0.44,
        },
        {
          "lote":"040",
          "IT":2.657,
          "BAJ":7.8,
          "PRO":"CAP",
          "PRO2":0.67,
        },
      ],
      "truckLoad": 26000,
      "building": [
        {
          "NAVEID": 1,
          "silo": [
            {
              "SILOID": 1,
              "capacidad": 13000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            }
          ]
        },
        {
          "NAVEID": 2,
          "silo": [
            {
              "SILOID": 1,
              "capacidad": 13000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            }
          ]
        }
      ],
      "feedPlan": [
        {
          "ETAPAALIMENTACION": 4,
          "feed": [
            {
              "ITEMID": "0094",
              "ITEMNAME": "CL-02",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 7000,
              "CANT_REAL": 9005,
              "UNITID": "kg",
              "LOTE": "054",
              "DESVADMITIDA": 15,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 5,
          "feed": [
            {
              "ITEMID": "0104",
              "ITEMNAME": "CL-04",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 18000,
              "CANT_REAL": 20988,
              "UNITID": "kg",
              "LOTE": "054",
              "DESVADMITIDA": 10,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 6,
          "feed": [
            {
              "ITEMID": "0114",
              "ITEMNAME": "CC-21",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 80000,
              "CANT_REAL": 25892,
              "UNITID": "kg",
              "LOTE": "054",
              "DESVADMITIDA": 10,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 7,
          "feed": [
            {
              "ITEMID": "0214",
              "ITEMNAME": "CC-31",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 110000,
              "CANT_REAL": 9500,
              "UNITID": "kg",
              "LOTE": "054",
              "DESVADMITIDA": 50,
              "DESVADMITIDAINF": 50
            }
          ]
        }
      ]
    },
    {
      "dataareaid": "ac",
      "sector":2,
      "id": "31223",
      "name": "RODRIGUEZ PAEZ M CARMEN",
      "POBLACION": "TORRES DEL CARRIZAL",
      "EMAIL": "sprunera@vallcompanys.es",
      "LOTE": "032",
      "tipoGranja": 2,
      "rol": 30,
      "allowMedicado": 1,
      "procedencias": {
        "loteActual":
          {
            "altas":0,
            "bajas":0,
            "informacion":[
              {
                "nombre":"Otros",
                "codMadres":"",
                "codDestete":"",
                "altas":0,
                "bajas":0
              }
            ]
          }   
        ,
        "loteAnterior":
          {
            "altas":100,
            "bajas":78,
            "informacion":[
              {
                "nombre":"Otros",
                "codMadres":"",
                "codDestete":"",
                "altas":0,
                "bajas":0
              },
              {
                "nombre":"AGROPECUARIA DE CALERUEGA SL",
                "codMadres":"ES090640000004",
                "codDestete":"",
                "altas":100,
                "bajas":78
              },
            ]
          }
        
      },
      "pienso":{
        "loteActual":{
          "altas":0,
          "bajas":30000,
          "informacion":
          [
            {
              "tipo":"null",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-31",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-21",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
          ]
        },
        "loteAnterior":{
          "altas":0,
          "bajas":242626,
          "informacion":[
            {
              "tipo":"CL-02",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"null",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-31",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-21",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CL-04",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
          ]
        }
      },
      "lotes":[
        {
          "lote":"036",
          "IT":2.274,
          "BAJ":5.19,
          "PRO":"INT",
          "PRO2":1.59,
        },
        {
          "lote":"037",
          "IT":2.532,
          "BAJ":0.09,
          "PRO":"CAP",
          "PRO2":1.84,
        },
        {
          "lote":"038",
          "IT":2.158,
          "BAJ":2,
          "PRO":"INT",
          "PRO2":0.15,
        },
        {
          "lote":"039",
          "IT":2.183,
          "BAJ":3.4,
          "PRO":"INT",
          "PRO2":0.44,
        },
        {
          "lote":"040",
          "IT":2.657,
          "BAJ":7.8,
          "PRO":"CAP",
          "PRO2":0.67,
        },
      ],
      "truckLoad": 26000,
      "building": [
        {
          "NAVEID": 1,
          "silo": [
            {
              "SILOID": 1,
              "capacidad": 16000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            },
            {
              "SILOID": 2,
              "capacidad": 16000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            }
          ]
        },
        {
          "NAVEID": 2,
          "silo": [
            {
              "SILOID": 3,
              "capacidad": 16000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            },
            {
              "SILOID": 4,
              "capacidad": 16000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            }
          ]
        }
      ],
      "feedPlan": [
        {
          "ETAPAALIMENTACION": 4,
          "feed": [
            {
              "ITEMID": "0094",
              "ITEMNAME": "CL-02",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 17570,
              "CANT_REAL": 26360,
              "UNITID": "kg",
              "LOTE": "032",
              "DESVADMITIDA": 15,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 5,
          "feed": [
            {
              "ITEMID": "0104",
              "ITEMNAME": "CL-04",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 45180,
              "CANT_REAL": 47496,
              "UNITID": "kg",
              "LOTE": "032",
              "DESVADMITIDA": 10,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 6,
          "feed": [
            {
              "ITEMID": "0114",
              "ITEMNAME": "CC-21",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 200800,
              "CANT_REAL": 4499,
              "UNITID": "kg",
              "LOTE": "032",
              "DESVADMITIDA": 10,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 7,
          "feed": [
            {
              "ITEMID": "0215",
              "ITEMNAME": "CC-36",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 326300,
              "CANT_REAL": 0,
              "UNITID": "kg",
              "LOTE": "032",
              "DESVADMITIDA": 50,
              "DESVADMITIDAINF": 50
            }
          ]
        }
      ]
    },
    {
      "dataareaid": "ac",
      "sector":3,
      "id": "31230",
      "name": "ENRIQUEZ SERRANO DAVID",
      "POBLACION": "VALDEPERDICES",
      "EMAIL": "sprunera@vallcompanys.es",
      "LOTE": "031",
      "tipoGranja": 2,
      "rol": 30,
      "allowMedicado": 1,
      "procedencias": {
        "loteActual":
          {
            "altas":0,
            "bajas":0,
            "informacion":[
              {
                "nombre":"Otros",
                "codMadres":"",
                "codDestete":"",
                "altas":0,
                "bajas":0
              }
            ]
          }   
        ,
        "loteAnterior":
          {
            "altas":100,
            "bajas":78,
            "informacion":[
              {
                "nombre":"Otros",
                "codMadres":"",
                "codDestete":"",
                "altas":0,
                "bajas":0
              },
              {
                "nombre":"AGROPECUARIA DE CALERUEGA SL",
                "codMadres":"ES090640000004",
                "codDestete":"",
                "altas":100,
                "bajas":78
              },
            ]
          }
        
      },
      "pienso":{
        "loteActual":{
          "altas":0,
          "bajas":30000,
          "informacion":
          [
            {
              "tipo":"null",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-31",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-21",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
          ]
        },
        "loteAnterior":{
          "altas":0,
          "bajas":242626,
          "informacion":[
            {
              "tipo":"CL-02",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"null",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-31",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-21",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CL-04",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
          ]
        }
      },
      "lotes":[
        {
          "lote":"036",
          "IT":2.274,
          "BAJ":5.19,
          "PRO":"INT",
          "PRO2":1.59,
        },
        {
          "lote":"037",
          "IT":2.532,
          "BAJ":0.09,
          "PRO":"CAP",
          "PRO2":1.84,
        },
        {
          "lote":"038",
          "IT":2.158,
          "BAJ":2,
          "PRO":"INT",
          "PRO2":0.15,
        },
        {
          "lote":"039",
          "IT":2.183,
          "BAJ":3.4,
          "PRO":"INT",
          "PRO2":0.44,
        },
        {
          "lote":"040",
          "IT":2.657,
          "BAJ":7.8,
          "PRO":"CAP",
          "PRO2":0.67,
        },
      ],
      "truckLoad": 26000,
      "building": [
        {
          "NAVEID": 1,
          "silo": [
            {
              "SILOID": 1,
              "capacidad": 15000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            },
            {
              "SILOID": 2,
              "capacidad": 15000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            }
          ]
        },
        {
          "NAVEID": 2,
          "silo": [
            {
              "SILOID": 3,
              "capacidad": 15000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            },
            {
              "SILOID": 4,
              "capacidad": 15000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            }
          ]
        }
      ],
      "feedPlan": [
        {
          "ETAPAALIMENTACION": 4,
          "feed": [
            {
              "ITEMID": "0094",
              "ITEMNAME": "CL-02",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 14350,
              "CANT_REAL": 17000,
              "UNITID": "kg",
              "LOTE": "031",
              "DESVADMITIDA": 15,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 5,
          "feed": [
            {
              "ITEMID": "0104",
              "ITEMNAME": "CL-04",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 36900,
              "CANT_REAL": 39007,
              "UNITID": "kg",
              "LOTE": "031",
              "DESVADMITIDA": 10,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 6,
          "feed": [
            {
              "ITEMID": "0114",
              "ITEMNAME": "CC-21",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 164000,
              "CANT_REAL": 0,
              "UNITID": "kg",
              "LOTE": "031",
              "DESVADMITIDA": 10,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 7,
          "feed": [
            {
              "ITEMID": "0215",
              "ITEMNAME": "CC-36",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 266500,
              "CANT_REAL": 0,
              "UNITID": "kg",
              "LOTE": "031",
              "DESVADMITIDA": 50,
              "DESVADMITIDAINF": 50
            }
          ]
        }
      ]
    },
    {
      "dataareaid": "vc",
      "sector":1,
      "id": "32053",
      "name": "PRUEBAS APP",
      "POBLACION": "PLA DE LA FONT, EL",
      "EMAIL": "sprunera@vallcompanys.es",
      "LOTE": "048",
      "tipoGranja": 2,
      "rol": 10,
      "allowMedicado": 0,
      "procedencias": {
        "loteActual":
          {
            "altas":0,
            "bajas":0,
            "informacion":[
              {
                "nombre":"Otros",
                "codMadres":"",
                "codDestete":"",
                "altas":0,
                "bajas":0
              }
            ]
          }   
        ,
        "loteAnterior":
          {
            "altas":100,
            "bajas":78,
            "informacion":[
              {
                "nombre":"Otros",
                "codMadres":"",
                "codDestete":"",
                "altas":0,
                "bajas":0
              },
              {
                "nombre":"AGROPECUARIA DE CALERUEGA SL",
                "codMadres":"ES090640000004",
                "codDestete":"",
                "altas":100,
                "bajas":78
              },
            ]
          }
        
      },
      "pienso":{
        "loteActual":{
          "altas":0,
          "bajas":30000,
          "informacion":
          [
            {
              "tipo":"null",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-31",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-21",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
          ]
        },
        "loteAnterior":{
          "altas":0,
          "bajas":242626,
          "informacion":[
            {
              "tipo":"CL-02",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"null",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-31",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CC-21",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
            {
              "tipo":"CL-04",
              "kgTeorico":0,
              "kgReal":0,
              "ultimo":"03/11/2020"
            },
          ]
        }
      },
      "lotes":[
        {
          "lote":"036",
          "IT":2.274,
          "BAJ":5.19,
          "PRO":"INT",
          "PRO2":1.59,
        },
        {
          "lote":"037",
          "IT":2.532,
          "BAJ":0.09,
          "PRO":"CAP",
          "PRO2":1.84,
        },
        {
          "lote":"038",
          "IT":2.158,
          "BAJ":2,
          "PRO":"INT",
          "PRO2":0.15,
        },
        {
          "lote":"039",
          "IT":2.183,
          "BAJ":3.4,
          "PRO":"INT",
          "PRO2":0.44,
        },
        {
          "lote":"040",
          "IT":2.657,
          "BAJ":7.8,
          "PRO":"CAP",
          "PRO2":0.67,
        },
      ],
      "truckLoad": 27000,
      "building": [
        {
          "NAVEID": 1,
          "silo": [
            {
              "SILOID": 1,
              "capacidad": 16000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            }
          ]
        },
        {
          "NAVEID": 2,
          "silo": [
            {
              "SILOID": 2,
              "capacidad": 12000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            }
          ]
        },
        {
          "NAVEID": 3,
          "silo": [
            {
              "SILOID": 3,
              "capacidad": 8000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            }
          ]
        },
        {
          "NAVEID": 4,
          "silo": [
            {
              "SILOID": 4,
              "capacidad": 16000,
              "ud": "Kg",
              "tipoPiensoSiloID": ""
            }
          ]
        }
      ],
      "feedPlan": [
        {
          "ETAPAALIMENTACION": 3,
          "feed": [
            {
              "ITEMID": "0074",
              "ITEMNAME": "CL-01",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 22100,
              "CANT_REAL": 0,
              "UNITID": "kg",
              "LOTE": "048",
              "DESVADMITIDA": 0,
              "DESVADMITIDAINF": 0
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 4,
          "feed": [
            {
              "ITEMID": "0094",
              "ITEMNAME": "CL-02",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 20400,
              "CANT_REAL": 0,
              "UNITID": "kg",
              "LOTE": "048",
              "DESVADMITIDA": 0,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 5,
          "feed": [
            {
              "ITEMID": "0104",
              "ITEMNAME": "CL-04",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 23800,
              "CANT_REAL": 0,
              "UNITID": "kg",
              "LOTE": "048",
              "DESVADMITIDA": 35,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 6,
          "feed": [
            {
              "ITEMID": "0119",
              "ITEMNAME": "CC-38",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 27200,
              "CANT_REAL": 0,
              "UNITID": "kg",
              "LOTE": "048",
              "DESVADMITIDA": 25,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 7,
          "feed": [
            {
              "ITEMID": "0114",
              "ITEMNAME": "CC-21",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 125800,
              "CANT_REAL": 0,
              "UNITID": "kg",
              "LOTE": "048",
              "DESVADMITIDA": 7,
              "DESVADMITIDAINF": 50
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 8,
          "feed": [
            {
              "ITEMID": "0214",
              "ITEMNAME": "CC-31",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 170000,
              "CANT_REAL": 0,
              "UNITID": "kg",
              "LOTE": "048",
              "DESVADMITIDA": 0,
              "DESVADMITIDAINF": 30
            }
          ]
        },
        {
          "ETAPAALIMENTACION": 99,
          "feed": [
            {
              "ITEMID": "9238",
              "ITEMNAME": "DESINFECTANTE 20Kg (Sepiolita)",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 0,
              "CANT_REAL": 0,
              "UNITID": "saco",
              "LOTE": "048",
              "DESVADMITIDA": 0,
              "DESVADMITIDAINF": 0
            },
            {
              "ITEMID": "9271",
              "ITEMNAME": "SECANTE 20 KG",
              "IMPORTANCIA": 0,
              "CANT_PREVISTA": 0,
              "CANT_REAL": 0,
              "UNITID": "saco",
              "LOTE": "048",
              "DESVADMITIDA": 0,
              "DESVADMITIDAINF": 0
            }
          ]
        }
      ]
    }
];

export const reports = [
  {
    id: 0,
    description: "Situación"
  },
  // {
  //   id: 1,
  //   descripcion: "Puesta"
  // }
]
export const medicineDelivery = [
  

  {
    id:1,
    warehouse: '97',
    itemId:1,
    itemDesc:'Medicina 1',
    batch: '123',
    animals:0,
    weight: 140,
    quantity: 5
  },
  {
    id:2,
    warehouse: '97',
    itemId:1,
    itemDesc:'Medicina 2',
    batch: '123',
    animals:0,
    weight: 140,
    quantity: 5
  },
  {
    id:3,
    warehouse: '97',
    itemId:1,
    itemDesc:'Medicina 3',
    batch: '123',
    animals:0,
    weight: 140,
    quantity: 5
  },
  {
    id:4,
    warehouse: '97',
    itemId:1,
    itemDesc:'Medicina 4',
    batch: '123',
    animals:0,
    weight: 140,
    quantity: 5
  },
  {
    id:5,
    warehouse: '97',
    itemId:1,
    itemDesc:'Medicina 5',
    batch: '123',
    animals:0,
    weight: 140,
    quantity: 5
  }
]