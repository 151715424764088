import React, { Component } from 'react';
import VirtualizedList from '../../Material/VirtualizedList';
import { IFarm, IMe, IFilter, IMedicineDelivery, IItemDelivery, IOrder, IBuilding, IBatch, IIOrder, IIIOrder } from '../../../Library/types';
import SearchField from '../../Material/SearchField';
import Loader from '../../Material/Loader';
import PaginatedTable from '../../Material/PaginatedTableMedicineWarehouseExchange';
import CreateModal from '../../Material/CreateDialog';
import OrderService from '../../../ApiClients/OrderService';
import { withParamsAndNavigate } from '../../Material/ReactRouter';
import { SelectorField, CheckBoxField, TextFieldInput } from '../../FormsWidgets';
import {filter, includes, cloneDeep, groupBy, findIndex, find} from 'lodash';
import Layout from '../../Layout';
import Footer from '../../Footer';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import List from '../../Material/MultiSelect';
import DatePicker from '../../Material/DatePicker';
import { ValidateText } from '../../../Library/vc_general';
import FormMedicineFormAddItem from './MedicineFormAddItem';

interface IIMedicineDeliveriesProps {
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    filters: IFilter;
    handleChangeFilter: Function;
    item: IItemDelivery;
    options:any;
}

interface IIMedicineDeliveriesState {
    deliveries: Array<IMedicineDelivery>;
    farm?: IFarm;    
    searchValue: string;
    filter_id: string;
    filteredDeliveries: Array<IMedicineDelivery>;
    loading: boolean;
    creationModalOpen:boolean;
    modalText:any;
    modalType:number;
    modalAction:number;
    titleModal: string;
    typeAction: number;
    inputText: string;
    loadingModal: boolean;
    bRegister: IItemDelivery;
    options?: any;    
    loadbeauty:boolean;

    order: IOrder;

    creationModalAddItem: boolean;

    itemListado:any;
    itemListadoIdx: number;
}

function tmRegister(businessId: any){
    return ({
        businessId:businessId,        
        warehouse: [],
        article: [],
        batch:'',
        animals:0, 
        weight: 0,
        quantity: 0
    });
};

const field_header = [
    [
        {
            id: 'date',
            name: 'Fecha',
            required: true,
            style:3,
            typeField:3,
        },
    ],
    [
        {
            id: 'warehouseId',
            name: 'Almacén',
            required: true,
            style:1,
            typeField:1,
        }
    ],    
];

function GetBasicOrder (warehouseId:string) {
    return {
        date: new Date(),
        batchId: ``,
        warehouseId: warehouseId,
        lines: []
    };
}

class IMedicineDeliveries extends Component<IIMedicineDeliveriesProps, IIMedicineDeliveriesState> {
    constructor(props: IIMedicineDeliveriesProps) {
        super(props);
        this.state = {
            deliveries: [],
            filteredDeliveries:[],            
            searchValue: "",
            filter_id: 'deliveries',
            loading: true,            

            loadbeauty: true,
            modalText:'',            
            modalType: -1,
            modalAction:-1,
            titleModal: `MENSAJE`,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            loadingModal: false,
            bRegister: tmRegister(this.props.params.business),

            itemListado: tmRegister(this.props.params.business),
            options:{
                article:[],
                inventLocationId: [],
                batchId: []

            },            

            order: GetBasicOrder(props.params.warehouseId),

            creationModalAddItem:false,

            itemListadoIdx: -1
        };
    }

    componentDidMount() {
        // this.getDeliveries(this.props.params.business,this.props.params.id);
        this.getOptions(this.props.params.business);
    }

    saveDeliveryMedicine = async (item:any, business:string) =>{
        let response = await OrderService.saveAskWarehouseInventory(item, business);        

        if(response.valid){
            this.setState({
                modalText: `La petición se ha realizado correctamente "${response.data}".`,
                loadingModal: false,
                modalAction: -1,
                modalType: 1,
                order: GetBasicOrder(this.props.params.warehouseId)
            })       
        } else {
            this.setState({
                modalText: response.msg,
                loadingModal: false,
                modalAction: -1,
                modalType: 1
            })
        }
    }

    getOptions = async (business:string, module:number = 0) => {
        const {params} = this.props;
        let article = await OrderService.getInventory(business, params.warehouseId);
        let warehouse = await OrderService.getWarehouse(business);

        const warehouseGet = find(warehouse, function(o){return params.warehouseId == o.id});

        let t_order = cloneDeep(this.state.order);

        if(warehouseGet)
            t_order.warehouseId = warehouseGet.id;

        this.setState({
            options:{
                //warehouseDestId: filter(warehouse, function(o){return [params.warehouseId].includes(o.id)}), 
                warehouseId: warehouse, 
                article:article,
            },
            loadbeauty: false,
            loading: false,
            order: t_order

        });
    }

    handleActionTable = (action:number, item:any, index:number) => {
        switch(action) {
            case 1: // Eliminar item
                this.setState({
                    itemListado: item,
                    itemListadoIdx: index,
                    modalAction: 1,
                    modalText: `¡ATENCIÓN! ¿Quiere eliminar el producto "${item.itemId} - ${item.itemName}"?`,
                    modalType: 2,
                    titleModal: `Eliminación producto`,
                    creationModalOpen: true
                })
                break;
            
            case 2: // Editar item
                this.setState({
                    itemListado: item.item,
                    itemListadoIdx: index,
                    creationModalAddItem: true
                })
                break;

            default:
                break;
        }

    }

    renderDeliveries=(lines:Array<IIOrder>)=>{
        // if(deliveries.length == 0)
        //     return (
        //         <div style={{textAlign:'center', padding:50}}>
        //             <p>
        //                 {`No tiene artículos configurados.`}
        //             </p>
        //         </div>
        //     )
        // let items = deliveries.map((delivery) => {
        //     return {
        //         ...delivery,                
        //     }
        // })
        return(
            <PaginatedTable
                data={lines}
                handleSearch={this.handleSearchChange}
                onClearSearchValue={this.handleClearSearchValue}                
                handleItemClick={this.handleItemClick}
                searchValue=''
                handleAction={this.handleActionTable}
            />
        );
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters.products);
        newFilters[id] = evt.target.value;
        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }

    handleItemClick=(item:any)=>{
        return ;
    }      
    
    handleFilterDeliveries=(deliveries:Array<IMedicineDelivery>)=>{
        let newDocuments = cloneDeep(deliveries);

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o){return includes(o.code_desc.toLowerCase(), searchValue) || includes(o.warehouse.toLowerCase(), searchValue) });
        }
        return newDocuments;
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: '', itemListado:tmRegister(this.props.params.business) });
    }

    onCloseCreationModalAddItem = () => {
        this.setState({ creationModalAddItem: false });
    }

    handleCreateModal = (typeAction: number) => {
        const {order, itemListadoIdx} = this.state;

        switch(typeAction){
            case 1:
                let nOrder = cloneDeep(order);
                nOrder.lines.splice(itemListadoIdx, 1);

                this.setState({
                    order: nOrder
                }, ()=>this.onCloseCreationModal())

                break;
            case 2:
                this.setState({
                    loadingModal: true
                }, ()=>this.saveDeliveryMedicine(order, this.props.params.business))
        }
    }

    handleAddDelivery = (item:any) => {  
        const {order} = this.state;

        let nOrder = cloneDeep(order);

        const index = findIndex(nOrder.lines, function(o) { return o.itemId == item.article.itemId});

        if(index > -1)
            nOrder.lines.splice(index, 1);

        let child_batch:Array<IIIOrder> = [];
        let total_qty = 0;

        item.batch.map((batch_item:any, index00:number)=>{
            const sub_qty = parseInt(batch_item.qty, 10);

            total_qty += sub_qty;

            child_batch.push({
                qty: sub_qty,
                vendorNumber: batch_item.inventBatchId,
                internalNumber: batch_item.inventBatchId,
                date: ""
            })
        })

        nOrder.lines.push({
            itemId: item.article.itemId,
            itemName: item.article.itemName,
            // quantity: itemListado.quantity,
            recipeCalcType: 0,
            inventBatch: child_batch,
            quantity: total_qty,
            item: item
        })

        this.setState({
            order: nOrder,
            creationModalAddItem: false
        })


        // this.setState({creationModalAddItem:true});
    }

    
    handleChangeDate = (id:string, evt:any) => {
        let data = cloneDeep(this.state.order);

        data[id] = evt
        
        this.setState({
            order: data
        })
    }

    shouldDisableToday = (date:any) => {
        return false;
    }

    VerifyField = (answer:any, question:any) => {
        // let answer = null;

        // if(header)
        //     answer = c_report.journalTable[0][question.id];
        // else
        //     answer = c_report.journalTable[0].farmJournalLines[building+1][origin][question.id];
        // // if(!question.required)
        // //     return [true, ""];


        if(question.required && (answer === null || answer.length == 0))
            return [false, "Debe rellenar este campo"];

        if(question.required || (!question.required && answer !== null && answer.length > 0))
        switch(question.inputType) {
            case "int":
                if(!ValidateText(question.inputType, answer, true)[0])
                    return [false, "El campo debe ser un entero"];
                break;
            case "float":
                if(!ValidateText(question.inputType, answer, true)[0])
                    return [false, "El campo debe ser un entero o decimal"];
                break;
        }

        return [true, ""]
    }

    handleChangeDataHeader = (id:string, evt:any, type?:string) => {

        let data = cloneDeep(this.state.order);
        
        if(!type)
            data[id] = evt.target.value;
        else {
            let txt = ValidateText(type, evt.target.value, false)

            if(txt[0])
                data[id] = txt[1];
        }

        this.setState({
            order: data
        })
    }

    handleDelivery = () => {
        this.setState({
            modalText: `¿Quiere hacer el recuento del almacén "${this.props.params.warehouseId}"?`,
            creationModalOpen: true,
            modalAction: 2,
            modalType: 2,
        })
    }

    f_report_header = () => {  
        let dale = field_header.map((array:any, index:number)=>{
            let fieldsN0 = array.map((field:any, index01:number)=> {
                switch(field.typeField){
                    case 1:
                        return(
                            <SelectorField
                                key={index01}
                                name={field.name}
                                id={field.id}
                                fieldsData={this.state.options[field.id]?this.state.options[field.id]:[]} 
                                handleFieldChange={(id:string, evt:any)=>this.handleChangeDataHeader(id, evt)}
                                // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                                disabled={field.id == "warehouseId"?true:false}
                                required={field.required}
                                style={1}
                                //onClick={(question.id == "Homogeneity"?(evt:any)=>handleCalcHomog(evt):undefined)}
                                deviceData={this.state.order}
                                className="dialog-item-25 color-sky-blue"
                            />
                        )
                    case 3:
                        return(
                            <div className='dialog-item-25'>
                                {DatePicker(field.id, field.name, "dd/MM/yyyy", this.handleChangeDate, this.state.order[field.id], false, this.shouldDisableToday, new Date('1970-01-01'))}
                            </div>   
                        )
                    // case 3:
                    //     return(
                    //         <div key={index} style={{marginBottom:20, display:'inline-block', marginRight:20}}>
                    //             {DatePicker(                                    
                    //                 n.id,
                    //                 n.name,
                    //                 "dd/MM/yyyy",
                    //                 this.handleChangeDate,
                    //                 item[n.id],
                    //                 [50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true,
                    //                 this.shouldDisableToday,
                    //                 new Date('1970-01-01'), undefined, 'color-sky-blue datepicker-100')
                    //             }
                    //         </div>
                    //     );
                    default:
                        return(null)
                }
            })

            return (
                <form key={index} noValidate autoComplete="off">
                    {fieldsN0}
                </form>
            )
        });

        return dale;
    }

    render() {
        const {filter_id, deliveries, farm, order, options, itemListado} = this.state;
        const f_deliveries = this.handleFilterDeliveries(deliveries);

        return (
            <div>
                <Layout 
                    showLateralMenu={true}
                    version={this.props.version}
                    me={this.props.myInfo}
                    title={this.props.params.warehouseId}
                    navigate={this.props.navigate}
                    loadbeauty={this.state.loadbeauty}
                />
                {!this.state.loading?
                    <div style={{ maxHeight: 'calc(100% - 112px)', overflowY:'auto' }}>
                        <div className='page-container'>
                        <Box style={{ display:'flex' }} sx={{ borderBottom: 1, borderColor: 'divider' }}>    
                            <IconButton onClick={()=>this.props.navigate(-1)}>
                                <BackIcon style={{fill:'#6d6d6d'}} />                                
                            </IconButton>
                            <h3>
                                {`Recuento inventario`}
                            </h3>
                        </Box>
                            
                        </div>
                        
                        <div className='tabla-container' style={{
                                position: 'absolute',
                                top: '130px',
                                left: 0,
                                right: 0,
                                bottom: '60px',
                                padding: '0 1em',                                
                            }}>                         

                            {this.f_report_header()}
                            <div style={{textAlign:'center', marginRight:0}}>
                                <Button disabled={order.warehouseId?false:true} size='medium' aria-label='add' startIcon={<AddIcon />} onClick={()=>this.setState({creationModalAddItem:true, itemListado: undefined})} style={{ width:'100%', backgroundColor:(!order.warehouseId?'#ddd':'#ff9639'), color:'white' }}>{`Añadir`}</Button>
                            </div>

                            <div style={{textAlign:'center', marginRight:0}}>
                                {this.renderDeliveries(order.lines)}
                            </div>

                            <div style={{textAlign:'center', marginRight:0}}>
                                <Button disabled={order.lines.length == 0} size='medium' aria-label='add' startIcon={<AddIcon />} onClick={()=>this.handleDelivery()} style={{ width:'100%', backgroundColor:(order.lines.length == 0?'#ddd':'#ff9639'), color:'white' }}>{`Realizar recuento`}</Button>
                            </div>

                        </div>
                        {this.state.creationModalOpen?
                            <CreateModal
                                onCloseCreationModal={this.onCloseCreationModal}
                                isOpen={this.state.creationModalOpen}
                                inputText={this.state.modalText}
                                typeModal={this.state.modalType}
                                action={this.handleCreateModal}
                                typeAction={this.state.modalAction}
                                loadingModal={this.state.loadingModal}
                                titleModal={this.state.titleModal}
                                closeButton={true}
                            />
                        :
                            null
                        }

                        {this.state.creationModalAddItem?
                            <FormMedicineFormAddItem
                                options={options}
                                handleCloseItemForm={this.onCloseCreationModalAddItem}
                                handleCreateItem={this.handleAddDelivery}
                                loading={false}
                                item={itemListado}
                            />
                        :
                            null
                        }
                    </div>
                    
                    //boton Finalizar
                :
                    <div style={{marginLeft:'50%',marginTop:200}}>
                        <Loader loading={true}/>
                    </div>
                }

                
                <Footer myInfo={this.props.myInfo} />
            </div>
        );
    }
}
export default withParamsAndNavigate(IMedicineDeliveries);