import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Typography } from '@material-ui/core';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ICheckboxesTagsProps {
  handleChangeSelect:Function;
  options: any;
  name: string;
  placeholder: string;
  multiple:boolean;
  data:any;
  id:string;
  autofocus?: boolean;
  autoHighlight?: boolean;
  helper?:string;
  noError?:boolean;
}

export default function CheckboxesTags(props:ICheckboxesTagsProps) {

  return (
    <Autocomplete
      autoHighlight={props.autoHighlight}
      multiple={props.multiple}
      value={props.data}
      limitTags={1}
      id={props.id}
      options={props.options}
      disableCloseOnSelect={props.multiple}
      onChange={(e:any, data:any)=>props.handleChangeSelect(e, data)}
      getOptionLabel={(option)=>(option.name?option.name:'')}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{maxWidth:'75vw'}}
      renderOption={(option, { selected }) => (
        <div
          style={{whiteSpace:'nowrap'}}
        >
        <React.Fragment>

          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        
        </React.Fragment>
        </div>

      )}
      renderInput={(params) => (
        <TextField error={!props.noError} helperText={props.helper} autoFocus={props.autofocus} {...params} variant="outlined" label={props.name} placeholder={props.placeholder} />
      )}
    />
  );
}