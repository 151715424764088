import React, { Component } from 'react';
import VirtualizedList from './Material/VirtualizedList';
import { IFarm, IMe, IFilter, IReport } from '../Library/types';
import SearchField from './Material/SearchField';
import Loader from './Material/Loader';
import {searchFarms} from '../Library/helpers'
import FarmListItem from './FarmListItem';
import OrderService from '../ApiClients/OrderService';
import { withParamsAndNavigate } from './Material/ReactRouter';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import {filter, includes, cloneDeep, groupBy} from 'lodash';
import Layout from './Layout';
import Footer from './Footer';
import AvailableReports from './ReportsAvailable';
import CreateReport from './Report_00';
import CreateModal from './Material/CreateDialog';

interface IFarmsProps {
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    filters: IFilter;
    handleChangeFilter: Function;
}

interface IFarmsState {
    farms: Array<IFarm>;
    farm?: IFarm;
    actions_farm?: IFarm;

    searchValue: string;
    filteredFarms: Array<IFarm>;
    loading: boolean;
    filter_id: string;
    options:Array<IFarm>;
    
    availableReports: Array<IReport>;
    loadingAvailableReports: boolean;
    openAvailableReports: boolean;

    openCreateReport: boolean;
    itemCreateReport?: IReport;
    itemCreateReportSave:boolean;
    itemCreateReportMsg?:string;
    itemCreateReportAction?:number;

    loadbeauty:boolean;

    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;

    questions?:any;
}

class Farms extends Component<IFarmsProps, IFarmsState> {
    constructor(props: IFarmsProps) {
        super(props);
        this.state = {
            farms: [],
            filteredFarms:[],            
            searchValue: (props.filters.farms["searchValue"]?props.filters.farms["searchValue"]:""),
            filter_id: 'farms',
            loading: true,
            options:[],
            availableReports: [],
            loadingAvailableReports: true,
            openAvailableReports: false,
            openCreateReport: false,
            itemCreateReportSave: false,

            loadbeauty: true,

            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
        };
    }

    componentDidMount() {
        this.getFarms();
    }

    resetLocalStorage = () => {
        localStorage.setItem('searchValueProduct', '')
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1:
                break;
            default:
                break;
        }
    }
        
    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false,
            questions: undefined,
            itemCreateReport: undefined });
    }

    getFarms = async () =>{
        let response = await OrderService.getFarms(false);        

        if(response !== undefined){
            this.setState({
                filteredFarms: searchFarms(response, this.state.searchValue),
                farms: response,
                loading:false,
                // availableReports: reports,
                loadingAvailableReports: false,
                loadbeauty: false

            })
            /*
            if(response.length === 1) {
                this.resetLocalStorage();
                this.props.history.push(`/`)
                this.props.history.push(`/granja/${response[0].dataareaid}/${response[0].id}/productos`)
            } else{
                this.setState({
                    filteredFarms: searchFarms(response, this.state.searchValue),
                    farms: response,
                    loading:false
                })
            }*/            
        }
    }

    getAvailableReports = async (business:string) =>{
        let response = await OrderService.getAvailableReports(business);        

        if(response.valid){
            this.setState({
                loading:false,
                availableReports: response.data,
                loadingAvailableReports: false,
                loadbeauty: false
            },()=>this.handleFormAvailableReport(true))
            /*
            if(response.length === 1) {
                this.resetLocalStorage();
                this.props.history.push(`/`)
                this.props.history.push(`/granja/${response[0].dataareaid}/${response[0].id}/productos`)
            } else{
                this.setState({
                    filteredFarms: searchFarms(response, this.state.searchValue),
                    farms: response,
                    loading:false
                })
            }*/            
        } else {
            this.setState({
                loadingAvailableReports: false,
                loadbeauty: false,
                inputText: `¡ATENCIÓN! Error en la obtención de los partes disponibles.`,
                modalType: 1,
                creationModalOpen: true
            })
        }
    }

    getFarm = async (business:string, id:string) =>{
        let response = await OrderService.getFarm(business, id);
        
        this.setState({
            farm: response,                
            loadbeauty:false,
            openAvailableReports: false,
            openCreateReport: (this.state.openAvailableReports?true:false)
        })                    
    }

    getAvailableReport = async (business:string, id:string) =>{
        let response = await OrderService.getAvailableReport(business, id);
        
        this.setState({
            questions: [response[0].headerQuestions,response[0].linesQuestions]
        })
        // this.setState({
        //     farm: response,                
        //     loadbeauty:false,
        //     openAvailableReports: false,
        //     openCreateReport: (this.state.openAvailableReports?true:false)
        // })                    
    }

    resertMessageReport = (action:number) => {
        if(action == 2) // Error
            this.setState({
                itemCreateReportMsg: undefined
            })
        else
            this.setState({
                itemCreateReportMsg: undefined,
                openCreateReport: false
            })
    }

    saveReport = async (report:any, business:string) =>{
        let response = await OrderService.saveReport(report, business);        

        if(response.valid){
            this.setState({
                itemCreateReportMsg: response.data,
                itemCreateReportSave: false,
                itemCreateReportAction: 3
            })
            /*
            if(response.length === 1) {
                this.resetLocalStorage();
                this.props.history.push(`/`)
                this.props.history.push(`/granja/${response[0].dataareaid}/${response[0].id}/productos`)
            } else{
                this.setState({
                    filteredFarms: searchFarms(response, this.state.searchValue),
                    farms: response,
                    loading:false
                })
            }*/            
        } else {
            this.setState({
                itemCreateReportMsg: response.msg,
                itemCreateReportSave: false,
                itemCreateReportAction: 2
            })
        }
    }

    renderFarms=(farms:Array<IFarm>)=>{
        if(farms.length == 0)
            return (
                <div style={{textAlign:'center', padding:50}}>
                    <p>
                        {`No tiene granjas configuradas para visualizar.`}
                    </p>
                </div>
            )
        let items = farms.map((farm) => {
            return {
                ...farm,
                onItemClick: () => this.handleItemClick(farm),
                onInfoClick: () => this.handleInfoClick(farm),
                onReportClick: (evt:any) => this.handleReportClick(evt, farm),
                onMedicineClick: (evt:any) => this.handleMedicineClick(evt, farm),
                onMedicineAskClick: (evt:any) => this.handleMedicineAskClick(evt, farm),
            }
        })
        return(
            <VirtualizedList items={items} RowComponent={FarmListItem} itemSize={130} />
        );
    }

    handleCleanFilters = (evt:any)=>{
        evt.stopPropagation();
        this.props.handleChangeFilter(this.state.filter_id, {}, false);
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters.farms);
        newFilters[id] = evt.target.value;
        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);
    }

    RenderFilters = (documents:Array<IFarm>, arrayfilters:any) => {
        const grouped_sectordsc = groupBy(documents, function(register) {
            return register.sector;
        });

        const merged_sectordsc = Object.keys(grouped_sectordsc).map((name:string, idx:number)=>{
            return {
                id: name,
                name: name
            }
        });

        const grouped_dataareaid = groupBy(documents, function(register) {
            return register.business;
        });

        const merged_dataareaid = Object.keys(grouped_dataareaid).map((name:string, idx:number)=>{
            return {
                id: name,
                name: name
            }
        });

        const data:any = {
            sectorDesc: merged_sectordsc,
            areaID: merged_dataareaid,
        }

        let filters = this.state.farms.map((filter:any, index:number)=>{

            return (
                <SelectorField
                    key={index}
                    name={filter.name}
                    id={filter.id}
                    deviceData={arrayfilters}
                    fieldsData={data[filter.id]?data[filter.id]:[]}
                    handleFieldChange={this.handleChangeFilter}
                    required={filter.required}
                    style={filter.style}
                    className='filter-item-200px'
                />
            );
        })

        return filters;
    }

    handleItemClick = (farm: IFarm) => {        
        this.resetLocalStorage();
        this.props.navigate(`/farm/${farm.business}/${farm.id}`)
    }

    handleInfoClick = (farm: IFarm) => {
        this.props.navigate(`/farm/${farm.business}/${farm.id}`)
    }

    handleReportClick = (evt:any, farm: IFarm) => {
        evt.stopPropagation();        
        this.setState({
            actions_farm: farm,
            loadbeauty: true,
            loadingAvailableReports: true
            // availableReports: (filter(this.state.availableReports, function(o){return o.id == 0}))
        }, ()=>this.getAvailableReports(farm.business))
        
        // this.props.navigate(`/farm/${farm.business}/${farm.id}`)
    }

    handleMedicineClick = (evt:any, farm: IFarm) => {  
        evt.stopPropagation();       
        this.props.navigate(`/medicineDelivery/${farm.business}/${farm.id}`);
    }

    handleMedicineAskClick = (evt:any, farm: IFarm) => {  
        evt.stopPropagation();       
        this.props.navigate(`/medicineAsk2Farm/${farm.business}/${farm.id}`);
    }
    handleFilterFarms=(farms:Array<IFarm>)=>{
        let newDocuments = cloneDeep(farms);

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o){return includes(o.description.toLowerCase(), searchValue) || includes(o.business.toLowerCase(), searchValue) });
        }
        return newDocuments;
    }


    handleValueChange = (evt: any) => {
        if(this.state.farms) {
            let newFilters = cloneDeep(this.props.filters.farms);
            newFilters["searchValue"] = evt.target.value;

            this.props.handleChangeFilter(this.state.filter_id, newFilters);

            this.setState({ 
                searchValue: evt.target.value,
             })
        }
    }

    handleFormAvailableReport = (state:boolean) => {
        this.setState({
            openAvailableReports: state,
            questions: undefined,
            itemCreateReport: undefined
        })
    }

    handleClickAvailableReport = (report:IReport) => {
        // this.props.navigate(`/report/create/${report.id}`)
        const {actions_farm} = this.state;
        if(!actions_farm)
            return;

        if([0].indexOf(report.type) == -1) {
            this.setState({
                inputText: `¡ATENCIÓN! El parte aún no está configurado para ser usado.`,
                modalType: 1,
                creationModalOpen: true
            })
        } else {
            this.setState({
                loadbeauty: true,
                itemCreateReport: report,
            }, ()=> [this.getFarm(actions_farm.business, actions_farm.id),this.getAvailableReport(actions_farm.business, report.id)])
        }

    }

    handleFormCreateReport = (state:boolean) => {
        this.setState({
            openCreateReport: state,
            questions: undefined,
            itemCreateReport: undefined
        })
    }

    handleCreateReport = (report: IReport) => {
        const {actions_farm} = this.state;
        if(!actions_farm)
            return;

        this.setState({
            // openCreateReport: false,
            // itemCreateReport: undefined
            itemCreateReportSave: true
        }, ()=>this.saveReport(report, actions_farm.business))
    }

    render() {
        const {options, filter_id, farms, itemCreateReportSave, loadingAvailableReports, farm, openAvailableReports, availableReports, openCreateReport, itemCreateReport, questions} = this.state;
        const {myInfo, filters} = this.props;
        const apply_filters: any = filters[filter_id];

        const f_farms = this.handleFilterFarms(farms);
        
        return (
            <div>
                <Layout 
                    showLateralMenu={true}
                    version={this.props.version}
                    me={this.props.myInfo}
                    title={'Listado de Granjas'}
                    navigate={this.props.navigate}
                    loadbeauty={this.state.loadbeauty}
                />
                {!this.state.loading?
                    <div style={{ maxHeight: 'calc(100% - 112px)', overflowY:'auto' }}>
                        <div className='page-container'>
                            <div className='search-container'>
                                {/* <IconButton onClick={()=>this.props.navigate(-1)}>
                                    <BackIcon style={{fill:'#6d6d6d'}} />
                                </IconButton> */}
                                <SearchField 
                                    allowFilter 
                                    allowSearch={!this.state.loading} 
                                    value={this.state.searchValue} 
                                    onValueChange={this.handleValueChange} 
                                    placeholder="Buscar granja..." />
                            </div>
                            
                        </div>
                        <div className='listado-container' style={{
                                position: 'absolute',
                                top: '130px',
                                left: 0,
                                right: 0,
                                bottom: '60px',
                            }}>
                            {this.renderFarms(f_farms)}
                        </div>
                    </div>
                :
                    <div style={{marginLeft:'50%',marginTop:200}}>
                        <Loader loading={true}/>
                    </div>
                }

                {!loadingAvailableReports && openAvailableReports?
                    <AvailableReports
                        items={availableReports}
                        handleClickReport={(item:IReport)=>this.handleClickAvailableReport(item)}
                        handleClose={()=>this.handleFormAvailableReport(false)}
                        loading={this.state.loadbeauty}
                    />
                :
                    null
                }

                {myInfo && farm && openCreateReport && itemCreateReport && questions?
                    <CreateReport
                        item={itemCreateReport}
                        handleCreateReport={(item:IReport)=>this.handleCreateReport(item)}
                        handleClose={()=>this.handleFormCreateReport(false)}
                        farm={farm}
                        saving={itemCreateReportSave}
                        msg={this.state.itemCreateReportMsg}
                        questions={questions}
                        resetMessage={(action:number)=>this.resertMessageReport(action)}
                        action={this.state.itemCreateReportAction}
                        me={myInfo}
                    />
                :
                    null
                }

                {this.state.creationModalOpen?
                    <CreateModal
                        onCloseCreationModal={this.onCloseCreationModal}
                        isOpen={this.state.creationModalOpen}
                        inputText={this.state.inputText}
                        typeModal={this.state.modalType}
                        action={this.handleCreateModal}
                        typeAction={this.state.typeAction}
                        loadingModal={false}
                    />
                :
                    null
                }
                <Footer myInfo={this.props.myInfo} />
            </div>
        );
    }
}
export default withParamsAndNavigate(Farms);