import React from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import FilterListIcon from '@material-ui/icons/FilterList';
import { IMedicineDelivery } from '../../Library/types';
import SearchField from './SearchField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import { CheckBoxField } from '../FormsWidgets';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OverflowCell from './OverflowCell';
import { Button } from '@material-ui/core';
import CreateModal from './CreateDialog';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const ITEM_HEIGHT = 48;

interface OptionRow {
    disablePadding: boolean;
    id: number;
    label: string;
    numeric: boolean;
}

const optionsRow: OptionRow[] = [
    { id: 1, numeric: true, disablePadding: false, label: 'Editar registro' },
    // { id: 2, numeric: true, disablePadding: false, label: 'Editar EPIs' },
];

interface HeadCell {
    disablePadding: boolean;
    id: keyof IMedicineDelivery;
    label: string;
    numeric: boolean;
    order: boolean;
}

const headCells: HeadCell[] = [
    { id: 'itemId', numeric: false, disablePadding: false, label: 'Código', order: false },
    { id: 'itemName', numeric: false, disablePadding: false, label: 'Nombre', order: false },
    { id: 'inventBatch', numeric: false, disablePadding: false, label: 'Nº lotes', order: false },
    { id: 'quantity', numeric: true, disablePadding: false, label: 'Unidades', order: false },
    { id: '', numeric: true, disablePadding: false, label: '', order: false },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IMedicineDelivery) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: any;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof IMedicineDelivery) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={headCell.order}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={ArrowDropDownIcon}
                        >
                            <strong>{headCell.label}</strong>
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);

interface EnhancedTableToolbarProps {
    numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Nutrition
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: 20,
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            //border: '1px solid rgb(0, 43, 97, 0.60)',
            borderRadius: '5px',
            //backgroundColor:'#ddd'

        },
        addButton:{
            width:'100%',
            backgroundColor:'#ff9639',
            marginBottom:'1em'
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);


interface IPaginatedProps {
    data: Array<any>;
    handleSearch: Function;
    onClearSearchValue: Function;
    handleItemClick: Function;
    searchValue: string;
    handleAction: Function;
}

export default function EnhancedTable(props: IPaginatedProps) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof IMedicineDelivery>('id');
    const [selected, setSelected] = React.useState<number[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowOptionSelected, setRowOptionSelected] = React.useState(-1);
    const [rowOptionSelectedAnchor, setRowOptionSelectedAnchor] = React.useState(null);
    const [rowDeleteConfirm, setRowDeleteConfirm] = React.useState(false);
    const [rowDeleteId, setRowDeleteId] = React.useState(-1);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IMedicineDelivery) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = props.data.map((n: IMedicineDelivery) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleDelete = (evt:any, id:number) => {
        setRowDeleteConfirm(true);
        setRowDeleteId(id);
    }
    const handleDeleteClose = () => {
        setRowDeleteConfirm(false);
    }
    
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name: number) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);

    const OptionisOpened = (id: number) => {
        if (rowOptionSelected == id)
            return true;

        return false;
    }

    const handleClickCloseRowOptions = () => {
        setRowOptionSelected(-1);
        setRowOptionSelectedAnchor(null);
    }

    const handleClickOpenRowOptions = (evt: any, id: number) => {
        setRowOptionSelectedAnchor(evt.currentTarget);
        setRowOptionSelected(id);
    }

    const renderOptionsRow = (row: IMedicineDelivery) => {
        // const clicked = (action:number) => {
        //   props.handleClickOptionRow(row, action);
        //   handleClickCloseRowOptions();
        // }
        return (
            <div>
                <IconButton
                    size='small'
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(evt: any) => handleClickOpenRowOptions(evt, row.id)}
                >
                    <AddIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={rowOptionSelectedAnchor}
                    keepMounted
                    open={OptionisOpened(row.id)}
                    onClose={handleClickCloseRowOptions}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 150,
                        },
                    }}
                >
                    {/* {optionsRow.map((option:OptionRow) => (
          <MenuItem key={option.id} onClick={()=>clicked(option.id)}>
            {option.label}
          </MenuItem>
          ))} */}
                </Menu>
            </div>
        );
    }

    return (
        <div className={classes.root}>            
            <Paper style={{ overflow: 'hidden' }} className={classes.paper}>                
                <TableContainer style={{ maxHeight: 'calc((100vh - 110px) * .55)' }}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={props.data.length}
                        />
                        <TableBody>
                            {stableSort(props.data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any, index: number) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            //onClick={(event) => handleClick(event, row.name)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            {/* <TableCell padding="checkbox"></TableCell> */}
                                            <TableCell align="center"><OverflowCell text={row.itemId} /></TableCell>
                                            <TableCell align="center"><OverflowCell text={row.itemName} /></TableCell>
                                            <TableCell align="center"><OverflowCell text={row.inventBatch.length} /></TableCell>
                                            <TableCell align="center"><OverflowCell text={row.quantity} /></TableCell>
                                            <TableCell align="center">
                                                <IconButton aria-label="delete" onClick={(evt:any) =>props.handleAction(2, row, index)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton aria-label="delete" onClick={(evt:any) =>props.handleAction(1, row, index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"          
                    count={props.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage='Filas por página:'
                    labelDisplayedRows={({ from, to, count })=>{            
                        return `${from} - ${to} de ${count}`;
                    }}
                /> */}
            </Paper>
        </div>
    );
}
